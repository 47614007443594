import React, { FC } from 'react';

type ButtonProps = {
  type: 'button' | 'submit' | 'reset';
  onClick?: () => void;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  ariaLabel?: string;
};

const Button: FC<ButtonProps> = (props) => {
  const { children, ariaLabel } = props;

  return (
    <button {...props} aria-label={ariaLabel}>
      {children}
    </button>
  )
}

export default Button;
