import React, { FC } from 'react';

type SectionWrapperProps = {
  id: string;
  children: React.ReactNode;
  className?: string;
  position?: 'center' | 'start' | 'end';
};

const SectionWrapper: FC<SectionWrapperProps> = ({ id, children, className, position = 'center' }) => {
  return (
    <section id={id} className={className}>
      <div className={`contanier max-w-full sm:max-w-7xl mx-auto px-2.5 sm:px-8 flex flex-col items-${position} justify-center`}>
        {children}
      </div>
    </section>
  )
}

export default SectionWrapper;
