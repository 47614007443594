import React from 'react';

import { features, plans } from '../../../core/constants/common';

import {
  HeroSection,
  FeaturesSection,
  TeamProductivitySection,
  PricingSection
} from '../../../core/components/sections/home';

const Homepage = () => {
  return (
    <div id="home">
      <HeroSection />
      <FeaturesSection features={features} />
      <TeamProductivitySection />
      <PricingSection plans={plans} />
    </div>
  )
}

export default Homepage;
