import React, { FC } from 'react';

import { IFeature } from '@/core/interfaces/common';

import SectionWrapper from '../Wrapper';
import { FeatureCard } from '../../ui';

type FeaturesSectionProps = {
  features: IFeature[];
};

const FeaturesSection: FC<FeaturesSectionProps> = ({ features }) => {
  return (
    <SectionWrapper id="features-section" className="flex flex-col items-center justify-center py-14 sm:py-28 px-2 sm:px-0">
      <h2 className="text-center text-2xl sm:text-4xl font-bold text-dark-gray-800 mb-2 sm:mb-3.5 max-w-full sm:max-w-3xl">
        Lightning fast. Better privacy. Fairer cost.
      </h2>

      <p className="text-center text-xs sm:text-sm text-gray-300 font-normal mb-8 sm:mb-12 px-8 sm:px-0 max-w-full sm:max-w-2xl">
        You can choose continue to use centralized cloud storage but we have some better reasons for you to start moving to decentralized storage because it is simply the future of the internet.
      </p>

      <div className="grid grid-cols-12 items-center justify-center gap-4 sm:gap-6 w-full">
        {features.map((feature) => (
          <FeatureCard key={feature.id} {...feature} />
        ))}
      </div>
    </SectionWrapper>
  )
}

export default FeaturesSection;
