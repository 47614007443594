import React, { FC } from 'react';

import { ROUTES } from '../../../enums/common';

import { Link } from '../../ui';
import SectionWrapper from '../Wrapper';

type HeroSectionProps = {

};

const HeroSection: FC<HeroSectionProps> = () => {
  const commonStyle = 'text-sm sm:text-base font-medium px-8 sm:px-10 py-1.5 sm:py-2 border-2 text-center w-full sm:w-auto light-shadow rounded-xl whitespace-nowrap transition-colors duration-300 focus:outline-none active:outline-none';

  return (
    <SectionWrapper id="hero-section" className="pt-8 sm:pt-16 bg-light-blue">
      <h1 className="text-center text-3xl sm:text-5xl font-bold text-dark-gray-800 lea sm:leading-[55px] mb-3.5 max-w-full sm:max-w-3xl">
        File storage and sharing for remote teams that work together without subscription.
      </h1>

      <p className="text-center text-sm sm:text-lg text-gray-500 font-normal mb-6 px-8 sm:px-0 max-w-full sm:max-w-2xl">
        CloudValut is a online file manager on decentralized cloud storage and IPFS that allows you storage, share, collect files privately and team collaboration without subscription.
      </p>

      <div className="flex items-center justify-center gap-4 md:gap-6 relative z-20">
        <Link
          href={ROUTES.SIGN_UP}
          className={`${commonStyle} text-white px-8 py-1.5 bg-purple-500 border-2 border-purple-500 hover:bg-transparent hover:text-purple-500`}
        >
          Get Started
        </Link>

        <Link
          href={ROUTES.PRICING}
          className={`${commonStyle} text-dark-gray-500 px-8 py-1.5 bg-transparent border-dark-gray-500 hover:bg-dark-gray-500 hover:text-white`}
        >
          Pricing
        </Link>
      </div>

      <img src="/images/hero.png" alt="hero-image" className="w-fit mt-6 sm:-mt-8 px-8 sm:px-0 aspect-auto" />
    </SectionWrapper>
  )
}

export default HeroSection;
