import React from 'react';

const Footer = () => {
  return (
    <footer className="relative bottom-0 bg-deep-navy text-gray-300 text-center z-50">
      <div className="contanier max-w-full sm:max-w-7xl mx-auto py-6 px-2.5 sm:px-8">
        <div className="text-sm">© 2024 CloudVault. Powered by <span className="text-white">Two Stack Private Limited</span></div>
      </div>
    </footer>
  )
}

export default Footer;
