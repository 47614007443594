
export const navLinks = [
  {
    id: 1,
    name: 'Features',
    href: '/features',
  },
  {
    id: 2,
    name: 'Solutions',
    href: '/solutions',
  },
  {
    id: 3,
    name: 'Pricing',
    href: '/pricing',
  },
  {
    id: 4,
    name: 'Developers',
    href: '/developers',
  },
  {
    id: 5,
    name: 'Resource',
    href: '/resource',
  },
];

export const features = [
  {
    id: 1,
    icon: 'BsFillShieldLockFill',
    title: 'Lightweight',
    description: 'Designed to be lightweight to help you enhance team collaboration by organizing and sharing files in your projects without stress.',
    linkName: 'Products features',
    href: '#',
  },
  {
    id: 2,
    icon: 'FaCircleNodes',
    title: 'Decentralization',
    description: 'Store data securely on over 13k nodes worldwide instead of just a few vulnerable data centers with privacy and lightning speed by design.',
    linkName: 'How it works',
    href: '#',
  },
  {
    id: 3,
    icon: 'GiDiceSixFacesFour',
    title: 'No subscription',
    description: 'With Pay-as-you-go pricing, now you only pay for the resource you use without being charged a flat monthly fee even if you don’t use it up.',
    linkName: 'Metered billing',
    href: '#',
  },
];

export const plans = [
  {
    id: 1,
    name: 'Lite',
    storage:  {
      monthly: 50, // 50GB
      yearly: 0.5, // 0.5TB
    },
    price: {
      monthly: 99,
      yearly: 999,
    },
    teams: {
      monthly: 0,
      yearly: 0,
    },
    teamMembers: {
      monthly: 0,
      yearly: 0,
    },
    fileUploadSize: 2,
    customerSupport: false,
    ticketSupport: true,
    enabledBasicFeatures: true,
    basicFeatures: [],
    enabledAdvanedFeatures: false,
    advancedFeatures: [],
    recommended: false,
  },
  {
    id: 2,
    name: 'Basic',
    storage: {
      monthly: 100, // 100GB
      yearly: 1, // 1TB
    },
    price: {
      monthly: 199,
      yearly: 1999,
    },
    teams: {
      monthly: 15,
      yearly: 150,
    },
    teamMembers: {
      monthly: 15,
      yearly: 150,
    },
    fileUploadSize: 3,
    customerSupport: true,
    ticketSupport: false,
    enabledBasicFeatures: true,
    basicFeatures: [],
    enabledAdvanedFeatures: true,
    advancedFeatures: ['teams'],
    recommended: true,
  },
  {
    id: 3,
    name: 'Standard',
    storage: {
      monthly: 200, // 200GB
      yearly: 2, // 2TB
    },
    price: {
      monthly: 299,
      yearly: 2999,
    },
    teams: {
      monthly: -1,
      yearly: -1,
    },
    teamMembers: {
      monthly: -1,
      yearly: -1,
    },
    fileUploadSize: 3,
    customerSupport: true,
    ticketSupport: false,
    enabledBasicFeatures: true,
    basicFeatures: [],
    enabledAdvanedFeatures: true,
    advancedFeatures: ['teams'],
    recommended: false,
  },
];

