import React, { FC } from 'react';

import { DynamicIcon, Link } from '../index';


type CheckIconProps = {
  checked: boolean;
};

const CheckIcon: FC<CheckIconProps> = ({ checked }) => {
  let iconName = 'FaCheck';
  let style = 'bg-purple-50 text-purple-500 flex items-center justify-center text-sm w-6 h-6 rounded-full ';

  if (!checked) {
    iconName = 'FaTimes';
    style += ' bg-red-50 text-red-500'
  }

  return (
    <div className={style}>
      <DynamicIcon name={iconName} />
    </div>
  );
};

type PlanCardProps = {
  id: number;
  name: string;
  storage: {
    monthly: number;
    yearly: number;
  };
  price: {
    monthly: number;
    yearly: number;
  };
  teams: {
    monthly: number;
    yearly: number;
  };
  teamMembers: {
    monthly: number;
    yearly: number;
  };
  fileUploadSize: number;
  customerSupport: boolean;
  ticketSupport: boolean;
  enabledBasicFeatures: boolean,
  basicFeatures: string[],
  enabledAdvanedFeatures: boolean,
  advancedFeatures: string[];
  recommended: boolean;
  isYearly: boolean;
}

const PlanCard: FC<PlanCardProps> = ({
  id,
  name,
  storage,
  price,
  teams,
  teamMembers,
  fileUploadSize,
  customerSupport,
  ticketSupport,
  enabledBasicFeatures,
  enabledAdvanedFeatures,
  recommended,
  isYearly,
}) => {

  const storageUnit = !isYearly ? 'GB' : 'TB';

  let cardStyle = 'col-span-12 sm:col-span-4 bg-white rounded-3xl light-shadow border-2 p-0 w-full h-full relative ';
  let linkStyle = 'font-bold text-sm sm:text-base text-center py-2.5 sm:py-3 rounded-xl w-full mt-8 sm:mt-10 transition-colors duration-300 ';

  if (recommended) {
    cardStyle += 'border-dark-gray-700';
    linkStyle += 'bg-purple-500 text-white';
  } else {
    cardStyle += 'border-white';
    linkStyle += 'bg-purple-50 text-purple-500 hover:bg-purple-500 hover:text-white';
  }

  return (
    <div key={id} className={cardStyle}>
      {recommended && (
        <div className="absolute left-1/2 top-2 transform -translate-x-1/2 -translate-y-1/2 text-center">
          <span className="inline-flex items-center justify-center px-16 py-0.5 rounded-b-full text-xs font-normal bg-dark-gray-700 text-white">
            Recommended
          </span>
        </div>
      )}

      <div className="px-12 py-12 w-full">
        <div className="flex items-center justify-between w-full mb-8">
          <h6 className="text-xl font-medium text-gray-800">{name}</h6>
          <p className="bg-purple-50 border border-purple-500 rounded-lg px-6 py-1 text-sm text-purple-500 font-extrabold">{!isYearly ? storage.monthly : storage.yearly} {storageUnit}</p>
        </div>

        <div className="flex flex-col items-start gap-y-2.5 w-full">
          <p className="flex items-center gap-x-2.5 text-dark-gray-800">
            <span className="inline-flex items-center text-4xl font-extrabold">
              <DynamicIcon name="FaIndianRupeeSign" />{!isYearly ? price.monthly : price.yearly}
            </span>
            <span>/ {!isYearly ? 'month' : 'year'}</span>
          </p>

          <p className="text-sm font-normal text-gray-400">Billed {!isYearly ? 'monthly' : 'yearly'}</p>

          <Link href="#" className={linkStyle}>
            Get started
          </Link>
        </div>

        <hr className="my-8 border-t border-dashed" />

        <ul className="flex flex-col items-start gap-y-2.5 text-base text-gray-500">
          <li className="flex items-center gap-x-2.5"><CheckIcon checked={true} />{!isYearly ? storage.monthly : storage.yearly} {storageUnit} Storage</li>
          <li className="flex items-center gap-x-2.5"><CheckIcon checked={true} />{!isYearly ? storage.monthly : storage.yearly} {storageUnit} Transfer</li>
          <li className="flex items-center gap-x-2.5"><CheckIcon checked={!!(!isYearly ? teams.monthly : teams.yearly)} />{(!isYearly ? teams.monthly : teams.yearly) === -1 ? 'Unlimited' : !isYearly ? teams.monthly : teams.yearly} Teams</li>
          <li className="flex items-center gap-x-2.5"><CheckIcon checked={!!(!isYearly ? teamMembers.monthly : teamMembers.yearly)} />{(!isYearly ? teamMembers.monthly : teamMembers.yearly) === -1 ? 'Unlimited' : !isYearly ? teamMembers.monthly : teamMembers.yearly} Team members per team</li>
          <li className="flex items-center gap-x-2.5"><CheckIcon checked={true} />{fileUploadSize}GB file upload size </li>
          <li className="flex items-center gap-x-2.5"><CheckIcon checked={true} />{enabledBasicFeatures && enabledAdvanedFeatures ? 'Basic + Advanced' : 'Basic'} features included</li>
          <li className="flex items-center gap-x-2.5"><CheckIcon checked={true} />{ticketSupport ? 'Ticket base support' : customerSupport ? '24/7 customer support' : 'No support'}</li>
        </ul>
      </div>
    </div>
  );
};

export default PlanCard;
