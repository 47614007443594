import React, { FC } from 'react';

type LinkProps = {
  children: React.ReactNode;
  href: string;
  className?: string;
  onClick?: () => void;
};

const Link: FC<LinkProps> = (props) => {
  const { children } = props;

  return (
    <a {...props}>
      {children}
    </a>
  )
}

export default Link;
