import { FC } from 'react';

import Navbar from './Navbar';
import Footer from './Footer';

type LayoutProps = {
  children: React.ReactNode;
  className?: string
};
const Layout: FC<LayoutProps> = ({ children, className }) => {
  return (
    <div className="relative overflow-hidden animate__animated animate__fadeIn animate__delay-0.8s">
      <Navbar />

      <main className="contanier max-w-full mx-auto mt-11 sm:mt-16 h-screen overflow-y-auto overflow-x-hidden scrollbar scrollbar-w-4 scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-thumb-purple-600 scrollbar-track-purple-200">
        {children}
      </main>

      <Footer />
    </div>
  )
}

export default Layout;
