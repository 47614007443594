import React, { FC } from 'react';

enum ALIGNMENTS {
  LEFT = 'left',
  RIGHT = 'right',
}

type CustomToggleSwitchProps = {
  checked: boolean;
  onChange: () => void;
};

const CustomToggleSwitch: FC<CustomToggleSwitchProps> = (props) => {
  let labelStyle = 'flex items-center gap-x-2.5 cursor-pointer ';

  return (
    <label id="toggle-switch" htmlFor="toggle" className={labelStyle}>
      <div className="relative flex flex-col items-center">
        <div className="absolute top-2.5 flex items-center gap-x-12 z-50">
          <p className="">Personal</p>
          <p>Professional</p>
        </div>

        <div className="relative">
          <input type="checkbox" id="toggle" className="sr-only" {...props} />
          <div className="bg-purple-50 w-40 sm:w-60 h-6 sm:h-10 rounded-full z-50" />
          <div className="dot absolute left-0.5 top-0.5 bg-purple-500 w-5 sm:w-[120px] h-6 sm:h-9 rounded-full transition" />
        </div>
      </div>
    </label>
  );
};

export default CustomToggleSwitch;
