import React, { FC } from 'react';

import { DynamicIcon, Link } from '../index';

type FeatureCardProps = {
  id: number | string;
  icon: string;
  title: string;
  description: string;
  linkName: string;
  href: string;
};


const FeatureCard: FC<FeatureCardProps> = ({ id, icon, title, description, linkName, href }) => {
  return (
    <Link
      key={id}
      href={href}
      className="group p-8 sm:p-12 rounded-3xl col-span-12 sm:col-span-4 bg-purple-500 text-white border-2 border-purple-500 hover:text-dark-gray-800 hover:bg-transparent w-full h-full smooth-shadow hover:scale-105 hover:shadow-none transition ease-in-out duration-300 cursor-pointer"
    >
      <div className="bg-purple-50 flex flex-col items-center justify-center text-xl sm:text-3xl w-12 sm:w-16 h-12 sm:h-16 rounded-full light-shadow mb-6 sm:mb-8 text-purple-500 group-[&:hover]:bg-purple-500 group-hover:text-purple-50">
        <DynamicIcon name={icon} />
      </div>

      <h4 className="text-xl sm:text-2xl font-bold mb-3.5">
        {title}
      </h4>

      <p className="text-sm sm:text-base font-light mb-8">
        {description}
      </p>

      <div className="flex items-center gap-x-2.5 text-sm sm:text-base font-medium">
        {linkName}
        <div className="group-hover:ml-4 ease-in-out duration-100">
          <DynamicIcon name="FaArrowRightLong" size={16} />
        </div>
      </div>
    </Link>
  );
};

export default FeatureCard;
