import React, { FC } from 'react';

import SectionWrapper from '../Wrapper';
import { Link } from '../../ui';
import { ROUTES } from '../../../enums/common';

type TeamProductivityCardProps = {
  title: string;
  description: string;
  image: string;
};

const TeamProductivityCard: FC<TeamProductivityCardProps> = ({ title, description, image }) => {
  let cardStyle = 'col-span-12 sm:col-span-6 rounded-3xl px-8 sm:px-14 pt-12 sm:pt-16 flex flex-col items-center justify-center w-full h-full ';

  if (image === 'file-sharing') {
    cardStyle += 'bg-soft-ivory';
  } else if (image === 'file-request') {
    cardStyle += 'bg-light-blue';
  }

  return (
    <div className={cardStyle}>
      <h6 className="text-2xl sm:text-4xl font-bold text-purple-500 mb-3.5">
        {title}
      </h6>
      <p className="text-sm sm:text-base text-gray-400 max-w-96 text-center mb-4">
        {description}
      </p>
      <img src={`/images/${image}.png`} alt={image} className="w-fit h-auto aspect-auto" />
    </div>
  );
};

type TeamProductivitySectionProps = {};

const TeamProductivitySection: FC<TeamProductivitySectionProps> = () => {
  const linkStyle = 'text-sm sm:text-base font-medium px-8 sm:px-10 py-1.5 sm:py-2 border-2 text-center w-full sm:w-auto light-shadow rounded-xl whitespace-nowrap transition-colors duration-300 focus:outline-none active:outline-none';

  return (
    <SectionWrapper id="team-productivity-section" className="py-12 sm:py-28 px-2 sm:px-0" position="start">
      <h1 className="text-left text-3xl sm:text-5xl font-bold text-dark-gray-800 lea sm:leading-[55px] mb-8 sm:mb-16 max-w-full sm:max-w-3xl">
        Lightweight design, ready to use for <span className="text-purple-500">team productivity</span>.
      </h1>

      <div className="flex flex-col items-center justify-center gap-5 sm:gap-7 w-full">
        <div className="grid grid-cols-12 items-center justify-center gap-5 sm:gap-7 w-full">
          <TeamProductivityCard
            title="File Sharing"
            description="Securely share files within or outside your organization, and control access, track edits, and analyze the shared content stats."
            image="file-sharing"
          />

          <TeamProductivityCard
            title="Collect Files"
            description="You can collect and receive files in a secure environment, even if the other person doesn’t have a Deupload account."
            image="file-request"
          />
        </div>

        <div className="grid grid-cols-12 items-center justify-center gap-6 sm:gap-8 w-full bg-purple-50 rounded-3xl px-8 sm:px-12 py-6 sm:py-8">
          <div className="col-span-12 sm:col-span-6 flex flex-col items-center justify-end sm:hidden w-full">
            <img src="/images/team-collaboration.png" alt="team-productivity" className="w-4/5 h-auto aspect-auto" />
          </div>

          <div className="col-span-12 sm:col-span-6 flex flex-col items-start w-full">
            <h2 className="text-2xl sm:text-5xl font-bold text-dark-gray-800 mb-2 sm:leading-[55px] sm:mb-4 max-w-full sm:max-w-lg">
              <span className="text-purple-500">Team collaboration</span> in  one simple place with privacy come first.
            </h2>
            <p className="text-left text-sm sm:text-base text-gray-400 font-normal mb-8 sm:mb-12 max-w-full sm:max-w-sm">
              Securely share and work together with CloudVault's simple file storage and sharing, easy user management, unlimited file size, password protected links and more.
            </p>

            <div className="flex items-center justify-start gap-4 md:gap-6 relative z-20">
              <Link
                href={ROUTES.SIGN_UP}
                className={`${linkStyle} text-white px-8 py-1.5 bg-purple-500 border-2 border-purple-500 hover:bg-transparent hover:text-purple-500`}
              >
                Start now
              </Link>

              <Link
                href={ROUTES.FEATURES}
                className={`${linkStyle} text-dark-gray-500 px-8 py-1.5 bg-transparent border-dark-gray-500 hover:bg-dark-gray-500 hover:text-white`}
              >
                More features
              </Link>
            </div>
          </div>

          <div className="col-span-12 sm:col-span-6 sm:flex flex-col items-center justify-end hidden w-full">
            <img src="/images/team-collaboration.png" alt="team-productivity" className="w-4/5 h-auto aspect-auto" />
          </div>
        </div>
      </div>

    </SectionWrapper>
  )
}

export default TeamProductivitySection;
