import { Route, Routes } from 'react-router-dom';

import { ROUTES } from '../../core/enums/common';

import { Homepage } from '../pages/index';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path={ROUTES.HOME} element={<Homepage />} />
    </Routes>
  )
}

export default AppRoutes;
