import React, { FC, useState } from 'react';

import { IPlan } from '../../../interfaces/common';
import { ROUTES } from '../../../enums/common';

import SectionWrapper from '../Wrapper';
import { ToggleSwitch, PlanCard, Link, CustomToggleSwitch } from '../../ui';

type PricingSectionProps = {
  plans: IPlan[];
};


const PricingSection: FC<PricingSectionProps> = ({ plans }) => {
  const [isYearly, setIsYearly] = useState<boolean>(false);
  const [isPro, setIsPro] = useState<boolean>(false);

  const commonStyle = 'text-sm sm:text-base font-medium px-8 sm:px-10 py-2 border-2 text-center w-full sm:w-auto light-shadow rounded-xl whitespace-nowrap transition-colors duration-300 focus:outline-none active:outline-none';

  return (
    <SectionWrapper id="pricing-section" className="py-8 sm:py-20 px-2 sm:px-0 bg-gray-50/30" position="start">
      <h2 className="text-left text-2xl sm:text-4xl font-bold text-dark-gray-800 mb-2 sm:mb-3.5 max-w-full sm:max-w-3xl">
        Pricing Plan
      </h2>

      <p className="text-left text-xs sm:text-sm text-gray-300 font-normal mb-8 sm:mb-12 max-w-full sm:max-w-2xl">
        Access a complete decentralized storage with simple and transparent pricing.
      </p>

      <div className="flex items-center justify-between w-full">
        {/* <CustomToggleSwitch
          checked={isPro}
          onChange={() => setIsPro((prevIsPro) => !prevIsPro)}
        /> */}
        <div />

        <ToggleSwitch
          checked={isYearly}
          onChange={() => setIsYearly((prevIsYearly) => !prevIsYearly)}
          label="Billed Annually"
          labelAlign="right"
        />
      </div>

      <div className="relative grid grid-cols-12 items-center justify-center gap-6 sm:gap-8 w-full py-6 sm:py-8">
        <div className="absolute -bottom-12 sm:-bottom-16 -right-16 sm:-right-20 bg-purple-50 w-[400px] sm:w-[550px] h-[400px] sm:h-[550px] rounded-full" />

        {plans.map((plan) => (
          <PlanCard key={plan.id} isYearly={isYearly} {...plan} />
        ))}
      </div>

      <div className="flex flex-col sm:flex-row items-center justify-center gap-4 md:gap-6 relative z-20 mt-10 w-full">
        <Link
          href={ROUTES.SIGN_UP}
          className={`${commonStyle} text-white px-8 py-1.5 bg-purple-500 border-2 border-purple-500 hover:bg-transparent hover:text-purple-500`}
        >
          Getting Started
        </Link>

        <Link
          href={ROUTES.PRICING}
          className={`${commonStyle} text-dark-gray-500 px-8 py-1.5 bg-transparent border-dark-gray-500 hover:bg-dark-gray-500 hover:text-white`}
        >
          See detail pricing
        </Link>
      </div>
    </SectionWrapper>
  )
}

export default PricingSection;
