import React, { FC } from 'react';

enum ALIGNMENTS {
  LEFT = 'left',
  RIGHT = 'right',
}

type ToggleSwitchProps = {
  checked: boolean;
  onChange: () => void;
  labelAlign?: 'left' | 'right';
  label: string;
};

const ToggleSwitch: FC<ToggleSwitchProps> = (props) => {
  const { labelAlign = 'left', label } = props;

  let labelStyle = 'flex items-center gap-x-2.5 cursor-pointer ';

  if (labelAlign === ALIGNMENTS.RIGHT) {
    labelStyle += 'flex-row-reverse';
  }

  return (
    <label id="toggle-switch" htmlFor="toggle" className={labelStyle}>
      <div className="relative">
        <input type="checkbox" id="toggle" className="sr-only" {...props} />
        <div className="block bg-dark-gray-50 w-11 sm:w-[52px] h-6 sm:h-7 rounded-full"></div>
        <div className="dot absolute left-0.5 top-0.5 bg-white w-5 sm:w-6 h-5 sm:h-6 rounded-full transition"></div>
      </div>

      <div className="text-sm sm:text-lg text-gray-300 font-normal">
        {label}
      </div>
    </label>
  );
};

export default ToggleSwitch;
