export enum ROUTES {
  SIGN_IN = '/sign-in',
  SIGN_UP = '/sign-up',
  HOME = '/',
  FEATURES = '/features',
  SOLUTIONS = '/solutions',
  PRICING = '/pricing',
  DEVELOPERS = '/developers',
  RESOURCE = '/resource',
};
