import React, { FC, ReactNode } from 'react';
import { BrowserRouter } from "react-router-dom";

import Layout from '../../core/components/layout';


interface AppProviderProps {
  children: ReactNode;
}

export const AppProvider: FC<AppProviderProps> = ({ children }) => {
  return (
    <Layout>
      <BrowserRouter basename="/">{children}</BrowserRouter>
    </Layout>
  );
};


export default AppProvider