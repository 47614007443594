import { FC, useState, useEffect } from 'react';
import { GiHamburgerMenu } from "react-icons/gi";
import { FaTimes } from "react-icons/fa";

import { navLinks } from '../../constants/common';
import { ROUTES } from '../../enums/common';

import { Button, DynamicIcon, Link } from '../ui';


type NavItemsProps = {
  onClick?: () => void;
};

const NavItems: FC<NavItemsProps> = ({ onClick }) => {
  return (
    <ul className="flex flex-col items-center gap-3.5 sm:flex-row md:gap-1 relative z-20">
      {navLinks.map((item) => (
        <li
          key={item.id}
          className="font-normal text-center w-full sm:w-auto rounded-xl max-w-full"
        >
          <Link
            href={item.href}
            className="text-gray-500 text-sm sm:text-base hover:bg-purple-50 px-6 py-2 rounded-xl border-2 w-full sm:w-auto border-transparent hover:border-purple-50 hover:text-purple-500 transition-colors ease-in-out duration-300"
            onClick={onClick}
          >
            {item.name}
          </Link>
        </li>
      ))}
    </ul>
  );
};


const AuthButtons: FC<{}> = () => {
  const commonStyle = 'text-sm sm:text-base font-medium px-8 py-1.5 border-2 text-center w-full sm:w-auto light-shadow rounded-xl transition-colors duration-300 focus:outline-none active:outline-none';

  return (
    <div className="flex flex-col items-center gap-4 sm:flex-row md:gap-6 relative z-20">
      <Link
        href={ROUTES.SIGN_IN}
        className={`${commonStyle} text-dark-gray-500 px-8 py-1.5 bg-transparent border-dark-gray-500 hover:bg-dark-gray-500 hover:text-white`}
      >
        Sign In
      </Link>

      <Link
        href={ROUTES.SIGN_UP}
        className={`${commonStyle} text-white px-8 py-1.5 bg-purple-500 border-2 border-purple-500 hover:bg-transparent hover:text-purple-500`}
      >
        Create an account
      </Link>
    </div>
  );
};

type NavbarProps = {};

const Navbar: FC<NavbarProps> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isShadow, setIsShadow] = useState<boolean>(false);

  const toggleMenu = () => setIsOpen((prevIsOpen) => !prevIsOpen);
  const closeMenu = () => setIsOpen(false);

  /**
  * Applies a shadow to the navbar when the user scrolls up.
  * @remarks
  * This is a one-time effect. It is only applied when the user first
  * scrolls up.
  */
  const applyShadowOnScroll = () => {
    const isScrollingUp = window.scrollY > 0;
    setIsShadow(isScrollingUp);
  };

  useEffect(() => {
    window.addEventListener('scroll', applyShadowOnScroll);

    return () => {
      window.removeEventListener('scroll', applyShadowOnScroll);
    };
  }, [isShadow]);

  return (
    <header className={`fixed top-0 left-0 right-0 z-50 bg-white/50 backdrop-blur-sm transition-colors duration-500 ease-in-out ${isShadow ? 'light-shadow' : ''}`}>
      <div className="container max-w-full mx-auto px-3.5 py-3">
        <div className="flex justify-between items-center mx-auto">
          <div className="flex items-center gap-x-12">
            <Link href={ROUTES.HOME} className="text-neutral-400 font-bold text-xl hover:text-white transition-colors">
              <span className="flex items-center gap-x-2.5 text-xl sm:text-2xl text-purple-500 font-bold">
                <DynamicIcon name="PiVaultDuotone" /> <span>CloudVault</span>
              </span>
              {/* <img src="/images/logo.png" alt="logo" className="w-20 sm:w-28 h-auto" /> */}
            </Link>

            <nav className="sm:flex hidden">
              <NavItems />
            </nav>
          </div>

          <div className="flex items-center gap-x-12">
            <nav className="sm:flex hidden">
              <AuthButtons />
            </nav>

            {/* Toggle menu */}
            <Button
              type="button"
              onClick={toggleMenu}
              className="text-gray-500 hover:text-dark-gray-500 focus:outline-none sm:hidden flex"
              ariaLabel="Toggle menu"
            >
              {isOpen ? <FaTimes className="w-5 h-5" /> : <GiHamburgerMenu className="w-5 h-5" />}
            </Button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div className={`absolute left-0 right-0 bg-black-200 backdrop-blur-sm transition-all duration-300 ease-in-out overflow-hidden z-20 mx-auto sm:hidden block ${isOpen ? 'max-h-screen' : 'max-h-0'}`}>
        <nav className="space-y-8 p-5">
          <NavItems onClick={closeMenu} />

          <AuthButtons />
        </nav>
      </div>
    </header>
  )
}

export default Navbar;
