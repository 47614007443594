import React from "react";
import * as FaIcons from "react-icons/fa";   // Font Awesome Icons
import * as AiIcons from "react-icons/ai";   // Ant Design Icons
import * as MdIcons from "react-icons/md";   // Material Design Icons
import * as BsIcons from "react-icons/bs";   // Bootstrap Icons
import * as BiIcons from "react-icons/bi";   // BoxIcons
import * as DiIcons from "react-icons/di";   // Devicons
import * as FiIcons from "react-icons/fi";   // Feather Icons
import * as GiIcons from "react-icons/gi";   // Game Icons
import * as HiIcons from "react-icons/hi";   // Heroicons
import * as ImIcons from "react-icons/im";   // IcoMoon Icons
import * as IoIcons from "react-icons/io";   // Ionicons
import * as SiIcons from "react-icons/si";   // Simple Icons
import * as RiIcons from "react-icons/ri";   // Remix Icons
import * as TiIcons from "react-icons/ti";   // Typicons
import * as WiIcons from "react-icons/wi";   // Weather Icons
import * as GoIcons from "react-icons/go";   // Octicons
import * as CgIcons from "react-icons/cg";   // CSS.gg Icons
import * as Fa6Icons from "react-icons/fa6"; // Font Awesome 6 Icons
import * as PiIcons from "react-icons/pi"; // Font Awesome 6 Icons

// Combine all imported icon libraries
const iconLibraries = {
  ...FaIcons,
  ...AiIcons,
  ...MdIcons,
  ...BsIcons,
  ...BiIcons,
  ...DiIcons,
  ...FiIcons,
  ...GiIcons,
  ...HiIcons,
  ...ImIcons,
  ...IoIcons,
  ...SiIcons,
  ...RiIcons,
  ...TiIcons,
  ...WiIcons,
  ...GoIcons,
  ...CgIcons,
  ...Fa6Icons,
  ...PiIcons,
};

// Get the keys of iconLibraries object for typing purposes
type IconLibrariesKeys = keyof typeof iconLibraries;

interface DynamicIconProps {
  name: string; // Allow any string as we will check it dynamically
  size?: number;
  color?: string;
}

const DynamicIcon: React.FC<DynamicIconProps> = ({ name, size, color }) => {
  // Check if the iconName is a valid key in iconLibraries
  const IconComponent = iconLibraries[name as IconLibrariesKeys];

  // Return the icon if found, otherwise return null
  return IconComponent ? <IconComponent size={size} color={color} /> : null;
};

export default DynamicIcon;